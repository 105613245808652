import React, { useEffect, useState } from "react";
import './css/Welcome.css';
import NavbarHome from './navbars/NavbarHome';
import NavbarLogin from './navbars/NavbarLogin';
import { db, auth } from "./firebase";
import { collection, where, query, getDocs } from "firebase/firestore";
import { useAuthState }  from 'react-firebase-hooks/auth';
import {  Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Modal } from 'react-bootstrap';


function Home() {

  const [data, setData] = useState([]);
  const [countAndroid, setCountAndroid] = useState();
  const [counts, setCounts] = useState({}); // state to store counts for each company
  const [counts1, setCounts1] = useState({}); // state to store counts for each company
  
  const [loading, setLoading] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [use ] = useAuthState(auth);


    const fetchData = async () => {
        try {
            const q = query(collection(db, 'entreprises'), where('admin', '==', use.uid));
            const querySnapshot = await getDocs(q);
            const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            if (items.length > 0) {
                setData(items); // Store all companies
				data.forEach(item => {
					fetchAndroid(item.id);
				});
            } else {
                console.log('No companies found for this user.');
            }

            // Sort items in ascending order based on dateCreated
            const sortedItems = items.sort((a, b) => b.dateCreated - a.dateCreated);
            setData(sortedItems);
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Android count for each company
    const fetchAndroid = async (compID) => {
        const q = query(collection(db, "android"), where("compID", "==", compID));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
                setCounts((prevCounts) => ({
                    ...prevCounts,
                    [compID]: doc.data().count // Store the count for the specific company ID
                }));
                console.log(doc.id, " => ", doc.data());
            });
        } else {
            console.log("No such document!");
        }
    };
	
	// Fetch Android count for each company
    const fetchApple = async (compID) => {
        const q = query(collection(db, "apple"), where("compID", "==", compID));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
                setCounts1((prevCounts) => ({
                    ...prevCounts,
                    [compID]: doc.data().count // Store the count for the specific company ID
                }));
                console.log(doc.id, " => ", doc.data());
            });
        } else {
            console.log("No such document!");
        }
    };
	

	
    useEffect(() => { 
		if(use){
		  ///navigate('/redirect');
		}
		fetchData();
		//alert(use.uid);
        setTimeout(() => {
			fetchData();
			setLoading(false);
		}, 1500);
    }, [data]);
	
	const formatRelativeTime = (timestamp) => {
		if (!timestamp) return '';
		const date = timestamp.toDate();
		const now = new Date();
		const diff = now - date;

		const seconds = Math.floor(diff / 1000);
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		const weeks = Math.floor(days / 7);

		if (weeks > 0) return `il y a ${weeks} sem`;
		if (days > 0) return `il y a ${days} j`;
		if (hours > 0) return `il y a ${hours} h`;
		if (minutes > 0) return `il y a ${minutes} m`;
		return `il y a ${seconds}s`;
    };


    // Pendant le chargement, vous pouvez afficher un indicateur visuel de chargement ou simplement retourner null
	if (loading) {
        return <div className="loading"><><div className="fs-3 fw-normal text-dark p-5">
        <Spinner animation="grow" variant="success" role="status">
            <span className="visually-hidden text-light">Loading...</span>
        </Spinner>
    </div></> </div>;
    }

  return (
    <>
    <div>
        {use? (
          <NavbarHome />
        ):(
          <NavbarLogin />
        )}
    </div>

    <div className="" style={{ background: '#02a049', minHeight: '100vh' }}>

        <div className="justify-content-center">
			<br /> <br /> <br /> <br />
            <div className="container justify-content-center">
			    <div className="row mt-3">
				   <div className="mt-3 mb-2"><h3 className='text-white fw-bold'>Mes projets</h3> </div>
				   <hr style={{ borderColor: '#efefef', borderWidth: '2px', marginLeft: '1%', marginRight: '0%' }} />
				</div>
                <div className="row">
                    <>  
					    <Link to="/new-project" className="bg-add-project text-center p-1">
							<div>
								<i className="fas fa-plus text-success fs-1 me-2"></i>
								<h5 className="text-success text-center fs-5 fw-bold mt-1" style={{ }}> Nouvelle App</h5>
							</div>
						</Link>
						
						<Modal show={show} onHide={handleClose} animation={true} className="modal-custom" centered>
						    <Modal.Header closeButton className="pt-3 pb-2">
								<Modal.Title><h5 className='text-success fw-normal'>Nos formules</h5></Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="container mt-2">
									<div className="row div-formules pb-4 bg-white justify-content-center">
										<div className="col-sm-4 box-features pt-4 ps-4 mt-1">
											<h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Developers</b> </h5>
											<div className='box-details-desc pb-3'>
												<span className='details-desc '> Conçue pour tests des developpeurs </span>
											</div>
											<div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>   
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Jusqu'à 15 campagnes le mois. </h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 500k Push notifications autorisés. </h5> 
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 1 Go de stockage disponibles. </h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 01 base de données fournie. </h5> 
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> API Rest intégrée pour vos requêtes. </h5> 		
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>				
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Vos tests sur l'app Fleetbo devbo.</h5>       
											</div>
											<div className='box-details-prices'>
												<h3 className='fs-4 fw-bold text-success'> <i className="fa-solid fa-gift me-1"></i> Gratuit </h3>
												<h4 className='mt-2 fs-6 text-success' style={{fontWeight: '600'}}> <b className='ms-0 text-dark' style={{fontWeight: '530', fontSize: '13px'}}> 30 jours offerts</b> </h4>
												<h4 className='fs-6 text-success'> <i className="fa-solid fa-toggle-on me-1"></i> <b  style={{ fontWeight: '505'}}>Launch</b> après 30 jours</h4> 
												<Link to="/new-project/developers" className='btn-start bg text-center mt-2'>Démarrer <i className="fas fa-tachometer-alt me-2"></i></Link> 
											</div>
										</div>
										<div className="col-sm-4 box-features bf-launch pt-4 ps-4 ms-4 me-4" style={{ border: '3px solid #05b365;' }}>
											<h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Launch</b> </h5>
											<div className='box-details-desc pb-3'>
												<span className='details-desc '> Conçue pour les startups en démarrage </span>
											</div>
											<div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>
												<h5 className='details '><i className="fa-solid fa-check text-success fw-bold me-2"></i> Jusqu'à 300 campagnes par an. </h5> 
												<h5 className='details '><i className="fa-solid fa-check text-success fw-bold me-2"></i> 3 M Push notifications par mois. </h5>
												<h5 className='details '><i className="fa-solid fa-check text-success fw-bold me-2"></i> 50 Go de stockage disponibles. </h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 01 base de données fournie. </h5>   
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> API Rest intégrée pour vos requêtes. </h5> 
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>  						
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Vos fichiers apk et IPA en 24H.</h5>   
											</div>
											<div className='box-details-prices'>
												<h3 className='fs-4 fw-bold text-success'> 75 &euro;  </h3>
												<h4 className='mt-2 fs-6 text-success' style={{fontWeight: '600'}}> 15 &euro; / mois <b className='fs-6 me-1 ms-1 text-dark' style={{fontWeight: '400'}}>OU</b> <span className='text-dark tarif'><sup> 170 &euro; / an</sup></span></h4>
												<h4 className='fs-6 text-success'> <i className="fa-solid fa-gift me-1"></i> Premier mois offert </h4> 
												<Link to="/new-project/launch" className='btn-start bg text-center mt-2'>Démarrer <i class="fas fa-tachometer-alt me-2"></i></Link> 
											</div>
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>
					</>
                    {data.map((item, index) => (
                    item.status === false ? (
					<>
					    
						<div key={index} className="bg-box-project pt-3 ps-3 pe-3 pb-0 " >
							<h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>{item.name}</b> </h5>
							<div className='box-details-desc pb-1'>
								<span className='details-desc fw-bold '> Loading... 70% </span>
							</div>
							<div className='box-details-button mt-2'>
								<Link disabled className='disabled mt-3'>
									<Spinner animation="grow" variant="warning" role="status" size="sm">
											<span className="visually-hidden text-dark">Loading...</span>
									</Spinner>
									<Spinner animation="grow" variant="warning" role="status" size="sm" className='ms-1'>
											<span className="visually-hidden text-dark">Loading...</span>
									</Spinner>
									<Spinner animation="grow" variant="warning" role="status" size="sm" className='ms-1'>
											<span className="visually-hidden text-dark">Loading...</span>
									</Spinner>
								</Link>
							</div>
						</div>
	
					</>
                    ) : (
					<>
						<div key={index} className="bg-box-project pt-3 ps-3 pe-3 pb-0 " >
							<h4 className='mt-3 fw-bolder'> <b className='text-success fw-bolder'>{item.name}</b> </h4>
							<div className='box-details-desc pb-1'>
								<span className='details-desc mt-0 pt-0 '>
									{/* If countAndroid is available, display it */}
									<div className="d-flex" style={{height: '25px'}}>
									    <div className="fs-5">
											{counts1[item.id] !== undefined ? (
												<span className="fs-5">{counts1[item.id]} <i className="fa-brands fa-apple fs-5 ms-1"></i></span>
											) : (
												<span className="fs-6">0 <i className="fa-brands fa-apple "></i></span>
											)}
										</div>
										
										<div className="ms-3 fs-5">
											{counts[item.id] !== undefined ? (
												<span className="fs-5">{counts[item.id]} <i className="fa-brands fa-android fs-5 ms-1"></i></span>
											) : (
												<span className="fs-6">0 <i className="fa-brands fa-android "></i></span>
											)}
										</div>
									</div>
								</span>
								<div style={{marginTop: '10px'}}>
									<span className="details-desc fs-6 text-dark">Crée  {formatRelativeTime(item.dateCreated)}</span>
								</div>
							</div>
							
							<div className='box-details-button mt-3'>
								<Link to={`/project/${item.id}`} className='btn-start text-center mt-3'>Ouvrir <i className="fas fa-tachometer-alt me-2"></i></Link>
							</div>
						</div>
				    </>		
                    )
                    ))}
              </div> 
            </div>
        </div> 
    </div>
    </>
  );
}

export default Home;
