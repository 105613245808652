// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import Spinner from 'react-bootstrap/Spinner';

const ProtectedRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return (
      <>
        <div className="loading fs-3 fw-normal text-dark p-5">
          <Spinner animation="grow" variant="white" role="status">
            <span className="visually-hidden text-light">Loading...</span>
          </Spinner>
        </div>
      </>
      
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
