import React, { useState } from 'react';
import './css/Contact.css';
import {  Link } from 'react-router-dom';
import { useAuth } from '../firebase/AuthContext';
import { useAuthState }  from 'react-firebase-hooks/auth';
import { getDownloadURL, ref, uploadBytes, getStorage } from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { auth, storage, db } from '../firebase';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';



function Contact() {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
	const [color, setColor] = useState('');

    const [icLaunch, setIcLaunch] = useState(null); // 
    const [icNotification, setIcNotification] = useState(null); // 

	const [email, setEmail] = useState('');
	const [country, setCountry] = useState('');
	
	const [resultMessage, setResultMessage] = useState();
	const [messageType, setMessageType] = useState(''); // 'success' or 'error'
    const [loading, setLoading] = useState(false);
	
	const [use] = useAuthState(auth);


	// Fonction pour vérifier les dimensions de l'image
	const checkImageDimensions = (file, expectedWidth, expectedHeight) => {
		return new Promise((resolve, reject) => {
		  const img = new Image();
		  img.onload = () => {
			if (img.width === expectedWidth && img.height === expectedHeight) {
			  resolve(true);
			} else {
			  reject(`L'image doit avoir les dimensions de ${expectedWidth}x${expectedHeight} pixels.`);
			}
		  };
		  img.onerror = () => reject('Erreur lors du chargement de l\'image.');
		  img.src = URL.createObjectURL(file);
		});
	};

	const handleCountryChange = (event) => {
		setCountry(event.target.value);
	};

	const colorRegex = /^#[0-9A-Fa-f]{6}$/; // Format hexadécimal

	// Validation de la couleur
	const handleColorChange = (e) => {
		const newColor = e.target.value;
		setColor(newColor);

		if (!colorRegex.test(newColor) && newColor !== '') {
		setResultMessage('Le thème doit être un code couleur hexadécimal valide (Ex: #414141)');
		setMessageType('danger');
		} else {
		setResultMessage('');
		}
	};

    const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setResultMessage(''); // Clear previous result message

		try {
			
			// Vérification si tous les fichiers sont bien sélectionnés
			if (!icLaunch || !icNotification) {
			  setResultMessage('All fields must have valid files.');
			  setLoading(false);
			  return;
			}

			await checkImageDimensions(icLaunch, 512, 512);
			await checkImageDimensions(icNotification, 48, 48);

			if (icLaunch.size > 50 * 1024 * 1024) { // 50 Mo en octets
				 setLoading(false);
				 setResultMessage('Le fichier build ne doit pas dépasser 50 Mo.');
				 setMessageType('danger');
				 return;
			}

			// Vérification que le fichier icLaunch est un fichier .png ou .jpg
			const isPngOrJpgFile = icLaunch.name.endsWith('.png') || icLaunch.name.endsWith('.jpg');
			if (!isPngOrJpgFile) {
			setLoading(false);
			setResultMessage('Le fichier de icLaunch doit être au format .png ou .jpg');
			setMessageType('danger');
			return;
			}

			// Vérification que le fichier icNotification est un fichier .svg 
			const isSvgFile = icNotification.name.endsWith('.svg');
			if (!isSvgFile) {
			setLoading(false);
			setResultMessage('Le fichier de icNotification doit être au format .svg ');
			setMessageType('danger');
			return;
			}

			// Vérification du format de la couleur (format hexadécimal)
			if (!colorRegex.test(color)) {
				setLoading(false);
				setResultMessage('Le thème doit être un code couleur hexadécimal valide (Ex: #414141)');
				setMessageType('danger');
				return;
			}

			// Upload de l'icône de lancement dans Firebase Storage
			const launchStorageRef = ref(storage, `files/${icLaunch.name}`);
			await uploadBytes(launchStorageRef, icLaunch);
			const icLaunchFileURL = await getDownloadURL(launchStorageRef);

			// Upload de l'icône de notification dans Firebase Storage
			const notificationStorageRef = ref(storage, `files/${icNotification.name}`);
			await uploadBytes(notificationStorageRef, icNotification);
			const icNotificationFileURL = await getDownloadURL(notificationStorageRef);
			
			// Référence à la collection 'entreprises'
			const entrepriseRef = collection(db, 'contacts');

			// Ajouter un document à Firestore
			await addDoc(entrepriseRef, {
				  name: name,
				  description: description,
				  color: color,
				  icLaunchFileURL: icLaunchFileURL,
				  icNotificationFileURL: icNotificationFileURL,

				  email: email,
				  country: country,

				  status: false,
				  createdAt: serverTimestamp() // Utilisation de serverTimestamp pour la date
			});
			
			setName('');
			setDescription('');
			setColor('');
			setIcLaunch('');
			setIcNotification('');

			setCountry('');
			setEmail('');

			
			// Message de succès avec SweetAlert
			Swal.fire({
				icon: 'success',
				title: 'Projet créé',
				text: 'Cliquez sur le lien de connexion envoyé par mail.',
				confirmButtonText: 'OK',
				customClass: {
					confirmButton: 'btn btn-success' // Applique la classe Bootstrap "btn-success" au bouton
				}
			});
			setLoading(false);

		} catch (error) {
			console.error("Error submitting form: ", error);
			setLoading(false);
			setResultMessage(`Erreur lors de la soumission : ${error.message}`);
			setResultMessage(`Erreur: ${error}`);
			setMessageType('danger');
		}
	};


    return (
      <div className="App" style={{  minHeight: '100vh', marginBottom: '3%' }}>
        <nav className="navbar fixed-top">
            <div className='container'>
                <div className="navbar-left">
                    <Link onClick={() => window.history.back()} className='fs-5' >
                       <i className="fas fa-arrow-left ms-1 fw-bold text-success"></i>
                    </Link>
                </div>
				<div className="navbar-right">
					<span className='fs-6 text-dark fw-bold'> Nouvelle App </span>
                </div>
            </div>
        </nav>
		
		<br /> 
		<div className="container mt-3 pt-3" style={{  background: '#f2f2f2', borderRadius: '9px' }}>
		    <form onSubmit={handleSubmit}>
			    <div className='col-md-12 float-left ps-2 pe-2 pb-3'>

				    <h5 className='text-success fs-4 fw-bolder mt-3'>
					    <i className="fa-solid fa-mobile-screen-button me-1"></i> Mon App
					</h5>
					<br />
					{/* Project Section */}
					<h5 className='text-success fw-bolder mt-4 mb-4'>1. Projet</h5>
					<div className='input-box'>
						<h5 className='desc-input text-dark'> Nom (50 characters).</h5>
						<div className='mt-3'>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								className='form-control mt-2'
								placeholder=''
								maxLength="35"
								style={{ width: '100%'}}
							/>
						</div>
					</div>
					<br />
					<h5 className='desc-input text-dark'> Description (250 Characters).</h5>
					<div className='mt-3'>
						<textarea
							rows='3'
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							className='form-control mt-2 '
							placeholder=''
							maxLength="200"
						/>
					</div>
					<br />

					{/* Customization Section */}
					<h5 className='text-success fw-bolder mt-4'>2. Customisation</h5>

					<div className='input-box mt-4'>
						<h5 className='desc-input text-dark'> Charte graphique ou thème <span className='fs-6'>(Ex: #854858)</span></h5>
						<div className='mt-3'>
							<input
								type="text"
								value={color}
								onChange={handleColorChange}
								className='form-control mt-2 '
								placeholder=''
							/>
						</div>
					</div>
					<br />
					<div className='input-box mt-2'>
					    <h5 className='desc-input text-dark'> Icône de lancement <span className='fs-6'> (PNG or JPG & 512px * 512px)</span> </h5>
						<div className='mt-3'>
							<input
								type="file"
								className="form-control mt-2 "
								onChange={(e) => setIcLaunch(e.target.files[0])}
								required
							/>
						</div>
					</div>
					<br />
					<div className='input-box mt-2'>
					    <h5 className='desc-input text-dark'> Icône de notification <span className='fs-6'> (SVG & 48px * 48px)</span> </h5>
						<div className='mt-3'>
							<input
								type="file"
								className="form-control mt-2 "
								onChange={(e) => setIcNotification(e.target.files[0])}
								required
							/>
						</div>
					</div>
					<br />
					
					{/* Build Section */}
					<h5 className='text-success fw-bolder mt-4'>3. Développeur</h5>
		
					<div className='input-box'>
						<h5 className='desc-input text-dark'> Adresse e-mail </h5>
						<div className='mt-3'>
							<input
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className='form-control mt-2'
								placeholder=''
								maxLength="35"
								style={{ width: '100%'}}
							/>
						</div>
					</div>
                    <br />
					<div className='input-box mt-2'>
					        <h5 className='desc-input text-dark'> Pays de résidence </h5>
							<select id="country" className='form-control input' value={country} onChange={handleCountryChange}>
									<option value="">Selectionner</option>
									<option value="Belgium">Belgium</option>
									<option value="Cameroon">Cameroon</option>
									<option value="Canada">Canada</option>
									<option value="France">France</option>
									<option value="Ivory Coast">Ivory Coast</option>
									<option value="Germany">Germany</option>
									<option value="Suisse">Suisse</option>
									<option value="Togo">Togo</option>
									<option value="US">US</option>
							</select>
					</div>

					<br />

					<div className='col-md-3 mt-3 mb-3'>
						<button
							className='btn-form mt-2 mb-4 fw-bold' 
							type="submit"
							disabled={loading}
						>
							{loading ? 'Soumission...' : 'Créer'} <i className="fa-solid fa-mobile-screen-button me-1"></i>
						</button>
					</div>

					{/* Result Message */}
					{resultMessage && (
						<div className={`input-box mt-3 text-${messageType}`}>
							<h5 className='fs-6 fw-bold text-danger'>{resultMessage}</h5>
						</div>
					)}
				</div>
		    </form>
		</div>
      </div>
    );
  }
  
  export default Contact;
  