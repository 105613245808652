import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import './css/Navbar.css';
import './css/Home.css';
import logo1 from './img/logo1.png';
import assistant from './img/assistant.png';
import {  auth } from "../firebase";
import { useAuthState }  from 'react-firebase-hooks/auth';
import {  useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Logout from './../Logout';


const NavbarHome = () => {
	
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	return (

			<nav className="navbar fixed-top">
				<div className='container'>
					<div className="navbar-left">
						<Link to="/home">
							<img src={logo1} className="" alt="logo" /> 
						</Link>
					</div>
					<div className="navbar-right">
						{/*
						<a href="/messages" className="cart-icon">
							<i className="fas fa-envelope me-3 mt-2"></i>
							<span className="cart-count me-3 mt-2 fw-bold">3</span>
							update
						</a>
						*/}

						<Modal show={show} onHide={handleClose} animation={true} className="modal-custom" centered>
						    <Modal.Header closeButton className="pt-3 pb-2">
								<Modal.Title><h5 className='text-success fw-normal'>Nos formules</h5></Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="container mt-2">
									<div className="row div-formules pb-4 bg-white justify-content-center">
										<div className="col-sm-4 box-features pt-4 ps-4 mt-1">
											<h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Developers</b> </h5>
											<div className='box-details-desc pb-3'>
												<span className='details-desc '> Conçue pour tests des developpeurs </span>
											</div>
											<div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>   
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Jusqu'à 15 campagnes le mois. </h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 500k Push notifications autorisés. </h5> 
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 1 Go de stockage disponibles. </h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 01 base de données fournie. </h5> 
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> API Rest intégrée pour vos requêtes. </h5> 		
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>				
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Vos tests sur l'app Fleetbo devbo.</h5>       
											</div>
											<div className='box-details-prices'>
												<h3 className='fs-4 fw-bold text-success'> <i className="fa-solid fa-gift me-1"></i> Gratuit </h3>
												<h4 className='mt-2 fs-6 text-success' style={{fontWeight: '600'}}> <b className='ms-0 text-dark' style={{fontWeight: '530', fontSize: '13px'}}> 30 jours offerts</b> </h4>
												<h4 className='fs-6 text-success'> <i className="fa-solid fa-toggle-on me-1"></i> <b  style={{ fontWeight: '505'}}>Launch</b> après 30 jours</h4> 
												<Link to="/new-project/developers" className='btn-start bg text-center mt-2'>Démarrer <i className="fas fa-tachometer-alt me-2"></i></Link> 
											</div>
										</div>
										<div className="col-sm-4 box-features bf-launch pt-4 ps-4 ms-4 me-4" style={{ border: '3px solid #05b365;' }}>
											<h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Launch</b> </h5>
											<div className='box-details-desc pb-3'>
												<span className='details-desc '> Conçue pour les startups en démarrage </span>
											</div>
											<div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>
												<h5 className='details '><i className="fa-solid fa-check text-success fw-bold me-2"></i> Jusqu'à 300 campagnes par an. </h5> 
												<h5 className='details '><i className="fa-solid fa-check text-success fw-bold me-2"></i> 3 M Push notifications par mois. </h5>
												<h5 className='details '><i className="fa-solid fa-check text-success fw-bold me-2"></i> 50 Go de stockage disponibles. </h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 01 base de données fournie. </h5>   
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> API Rest intégrée pour vos requêtes. </h5> 
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>  						
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Vos fichiers apk et IPA en 24H.</h5>   
											</div>
											<div className='box-details-prices'>
												<h3 className='fs-4 fw-bold text-success'> 75 &euro;  </h3>
												<h4 className='mt-2 fs-6 text-success' style={{fontWeight: '600'}}> 15 &euro; / mois <b className='fs-6 me-1 ms-1 text-dark' style={{fontWeight: '400'}}>OU</b> <span className='text-dark tarif'><sup> 170 &euro; / an</sup></span></h4>
												<h4 className='fs-6 text-success'> <i className="fa-solid fa-gift me-1"></i> Premier mois offert </h4> 
												<Link to="/new-project/launch" className='btn-start bg text-center mt-2'>Démarrer <i className="fas fa-tachometer-alt me-2"></i></Link> 
											</div>
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>

						<Link to="/new-project" className='connexion text-center ps-3 pe-3' style={{ width: '150px', fontSize: '15px', borderRadius: '25px' }} >  
						    <i className="fas fa-plus text-white fw-bolder me-1"></i>  App
						</Link>
 
						<Link to="/documentation" className="cart-icon me-2">
							<img src={assistant} className="assistant" alt="logo" /> 
						</Link>
						<Logout />
					</div>
				</div>
			</nav>
			
	);
  
};

export default NavbarHome;