import React, { useEffect, useState } from "react";
import { db, auth } from "./../firebase";
import { collection, getDoc, getDocs, doc, addDoc, query, where, limit, orderBy, onSnapshot, serverTimestamp } from "firebase/firestore";
import { useAuthState }  from 'react-firebase-hooks/auth';

import {  Link, useParams  } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import './../css/Home.css';
import './../css/ProgressBar.css';

import android from './../img/android.png';
import apple from './../img/apple.png';



function Project() {

  //const { currentUser } = useAuth();
    const [notifications, setNotifications] = useState(JSON.parse(localStorage.getItem('notifications')) || null);
    const [isLoading, setIsLoading] = useState(true);
	const [loading, setLoading] = useState(false);
  
    const [data, setData] = useState([]);
    const [name, setName] = useState();
	const [projectId, setProjectId] = useState();
	const [resultMessage, setResultMessage] = useState();
  
    const [countAndroid, setCountAndroid] = useState();
	const [androidState, setAndroidState] = useState();
	const [androidVersion, setAndroidVersion] = useState();
	const [androidBuild, setAndroidBuild] = useState();
	
	const [countApple, setCountApple]     = useState();
	const [appleState, setAppleState]     = useState();
	const [appleVersion, setAppleVersion] = useState();
	const [appleBuild, setAppleBuild]     = useState();

    const { id }         = useParams();  // Récupère l'id du paramètre dynamique dans l'URL
	const [user]         = useAuthState(auth);
	


	const fetchData = async () => {
		
			const docRef = doc(db, "entreprises", id);
			const docSnap = await getDoc(docRef);
		  
			if (docSnap.exists()) {
			  const docData = docSnap.data();
			  const data = {
				name: docData.name, 
			  };
			  setData(data);
			  setName(docData.name);
			  setProjectId(id);
			  localStorage.setItem('projectName', docData.name);
			} else {
			  console.log("No such document!");
			}
	};

	
	const fetchAndroid = async (compID) => {
		const q = query(
		    collection(db, "android"),
		    where("compID", "==", compID),
		    orderBy("dateGenerate", "desc"), // Trier par le champ 'createdAt' en ordre décroissant
		    limit(1) // Limiter à 1 document
		);
		const querySnapshot = await getDocs(q);
		setCountAndroid(querySnapshot.size);

		if (!querySnapshot.empty) {
			querySnapshot.forEach((doc) => {
				setAndroidState(doc.data().status);
				setAndroidBuild(doc.data().buildpercent);
				setAndroidVersion(doc.data().version);
				//alert(doc.data().count);
				console.log(doc.id, " => ", doc.data());
			});
		} else {
			setAndroidState('false');
			console.log("No such document!");
		}
	};
	
	const fetchApple = async (compID) => {
		const q = query(
		    collection(db, "apple"),
		    where("compID", "==", compID),
		    orderBy("dateGenerate", "desc"), // Trier par le champ 'createdAt' en ordre décroissant
		    limit(1) // Limiter à 1 document
		);
		const querySnapshot = await getDocs(q);
		setCountApple(querySnapshot.size);

		if (!querySnapshot.empty) {
			querySnapshot.forEach((doc) => {
				setAppleState(doc.data().status);
				setAppleBuild(doc.data().buildpercent);
				setAppleVersion(doc.data().version);
				//alert(doc.data().count);
				console.log(doc.id, " => ", doc.data());
			});
		} else {
			setAppleState('false');
			console.log("No such document!");
		}
	};
	
		
    useEffect(() => {

        fetchData();
		fetchAndroid(id);
        fetchApple(id);		

		const idNotif = 'xgPbibFSPa585I0rQOML'; // Define id here
		const collectionRef = collection(db, `Goupnotifs/${idNotif}/notifications`);

		const unsubscribe = onSnapshot(collectionRef, (collectionSnap) => {
		  if (!collectionSnap.empty) {
			const newNotifications = collectionSnap.docs.map((doc) => ({
			  id: doc.id,
			  ...doc.data()
			}));
			
			const cachedData = JSON.parse(localStorage.getItem('notifications'));
			if (JSON.stringify(newNotifications) !== JSON.stringify(cachedData)) {
			  setNotifications(newNotifications);
			  localStorage.setItem('notifications', JSON.stringify(newNotifications));
			}

			setIsLoading(false);
		  } else {
			alert('No such notifications!');
			console.log('No such notifications!');
			setIsLoading(false);
		  }
		});

		return () => unsubscribe();

    }, [id]);
	
	
    /***** GENERATE FIRST TIME  *****/
	const handleBuildApple = async () => {
		setLoading(true);
		setResultMessage(''); // Clear previous result message
		
		// Vérification de l'existence de l'utilisateur avant d'essayer de l'utiliser
		if (!user) {
			setLoading(false);
			setResultMessage('You must be logged in to proceed.');
			return;
		}
		
		try {

			// Référence à la collection 'apple'
			const appleRef = collection(db, 'apple');

			// Ajouter un document à Firestore
			await addDoc(appleRef, {
				  buildpercent: '0',
				  status: 'pending',
				  version: 1,
				  compID: user.uid,
				  dateGenerate: serverTimestamp()
			});

			setLoading(false);
		} catch (error) {
			console.error("Error submitting form: ", error);
			setLoading(false);
		}
	};

	
	const handleBuildAndroid = async () => {
		setLoading(true);
		setResultMessage(''); // Clear previous result message
		
		// Vérification de l'existence de l'utilisateur avant d'essayer de l'utiliser
		if (!user) {
			setLoading(false);
			setResultMessage('You must be logged in to proceed.');
			return;
		}
		
		try {

			// Référence à la collection 'android'
			const androidRef = collection(db, 'android');

			// Ajouter un document à Firestore
			await addDoc(androidRef, {
				  buildpercent: '0',
				  status: 'pending',
				  version: 1,
				  compID: user.uid,
				  dateGenerate: serverTimestamp()
			});

			setLoading(false);
		} catch (error) {
			console.error("Error submitting form: ", error);
			setLoading(false);
		}
	};
	/***** GENERATE FIRST TIME END  *****/
	
	
	/***** GENERATE UPDATE TIME  *****/
	const UpdateBuildApple = async () => {
		setLoading(true);
		setResultMessage(''); // Clear previous result message
		
		if (!user) {
			setLoading(false);
			setResultMessage('You must be logged in to proceed.');
			return;
		}

		try {
			
			// Ensure that the current appleVersion state is properly used
            const newVersion = parseInt(appleVersion) + 1; // Increment the version by 1

			// Référence à la collection 'apple'
			const appleRef = collection(db, 'apple');

			// Ajouter un document à Firestore
			await addDoc(appleRef, {
				  buildpercent: '0',
				  status: 'pending',
				  version: newVersion,
				  compID: user.uid,
				  dateGenerate: serverTimestamp()
			});

			setLoading(false);
		} catch (error) {
			console.error("Error submitting form: ", error);
			setLoading(false);
		}
	};

	
	const UpdateBuildAndroid = async () => {
		setLoading(true);
		setResultMessage(''); // Clear previous result message
		
		// Vérification de l'existence de l'utilisateur avant d'essayer de l'utiliser
		if (!user) {
			setLoading(false);
			setResultMessage('You must be logged in to proceed.');
			return;
		}	

		try {
			
			// Ensure that the current appleVersion state is properly used
            const newVersion = parseInt(androidVersion) + 1; // Increment the version by 1

			// Référence à la collection 'android'
			const androidRef = collection(db, 'android');

			// Ajouter un document à Firestore
			await addDoc(androidRef, {
				  buildpercent: '0',
				  status: 'pending',
				  version: newVersion,
				  compID: user.uid,
				  dateGenerate: serverTimestamp()
			});

			setLoading(false);
		} catch (error) {
			console.error("Error submitting form: ", error);
			setLoading(false);
		}
	};
	/***** GENERATE UPDATE END  *****/
	

    // Pendant le chargement, vous pouvez afficher un indicateur visuel de chargement ou simplement retourner null
	if (loading) {
        return  <div className="loading"><><div className="fs-3 fw-normal text-dark p-5">
					<Spinner animation="grow" variant="success" role="status">
						<span className="visually-hidden text-light">Loading...</span>
					</Spinner>
				</div></> </div>;
    }



    return (
		<div className="" style={{ background: '#02a049', minHeight: '100vh',overflowX: 'hidden' }}>

			<div className="justify-content-center ">
				<nav className="navbar fixed-top">
					<div className='container'>
						<div className="navbar-left">
							<Link  onClick={() => window.history.back()} className='fs-5'>
							   <i className="fas fa-arrow-left ms-1 fw-bold text-success"></i>
							</Link>
						</div>
						<div className="navbar-right">
							<span className='fs-6 fw-bold text-success me-3' style={{ fontWeight:"900"}}>
								<i className="fa-solid fa-mobile-screen-button me-1"></i> 
							</span>
							<Link to={`/update-project/${projectId}`} className='connexion text-center ps-3 pe-3' style={{ width: '170px', fontSize: '15px', borderRadius: '25px' }} >  
							    Mise à jour
							</Link>
						</div>
					</div>
				</nav>
				<br /> <br /> <br />
				<div className="container justify-content-center ">
					<br /> <br /> <br />
					<div className="row justify-content-center text-center">
						<h3 className="fw-bolder text-white mb-3"><i className="fa-solid fa-mobile-screen-button me-1"></i>  {name}</h3>
						<hr style={{ borderColor: '#efefef', borderWidth: '2px' }} />
						<br /> <br />
						{/****** Apple ******/}
						<div className="col-sm-3 box-features ms-3 me-3 pt-3 ps-3 pe-3 pb-0 " style= {{height: '360px'}}>
							<div className='pb-1'  >
								<img className='apps-image' alt="Chargement"  style= {{textAlign: 'center',  width: '50%'}} src={apple} />
							</div>
							<div className='pb-1'  >
								<span className='details-desc text-dark fw-bold mt-0 pt-0 '> App Store</span> <br />
							</div>
							<div className='pb-1'  >
								<span className='details-desc fs-6 fw-bold mt-0 pt-0 '> { countApple } <i className="fa-solid fa-download ms-1"></i>  </span> <br />
							</div>
							{
								appleState === "false" ? (
									<>
										<div className=''>
											<button onClick={handleBuildApple} className='btn-start text-center mt-3'>
												Générer <i className="fa-solid fa-mobile-screen-button me-2"></i> 
											</button>
										</div>
										<div className='mt-2 pb-2'>
											<span className="details-desc text-success " style={{ fontSize: '14px', textDecoration: 'underline', fontWeight: '550'}}>Aucune version </span>
										</div>
									</>
								) : appleState === "true" ? (
								    <>
										<div className=''>
											<button onClick={UpdateBuildApple} className='btn-start text-center mt-3'>
												Publier <i className="fa fa-share-alt me-2" aria-hidden="true"></i>
											</button>
										</div>
										<div className='mt-2'>
											<span className="text-success" style={{ fontSize: '14px', textDecoration: 'underline', fontWeight: '550' }}> 
											        Version {appleVersion} 
													<i className="fa-solid fa-mobile-screen-button ms-1"></i> 
											</span>
										</div>
									</>

								) : appleState === "pending" ? (
									appleBuild === "0" ? (
										<>
											<div className=''>
												<button  className='btn-generate0 text-center mt-3'>
													<span>Chargement... {appleBuild}%</span>
												</button>
											</div>
											<div className='mt-2'>
												<span className="details-desc text-success " style={{ fontSize: '14px'}}>En cours de construction...</span>
											</div>
										</>
									) : appleBuild === "25" ? (
										<>
											<div className=''>
												<button className='btn-generate25 text-center mt-3'>
													<span>Chargement... {appleBuild}%</span>
												</button>
											</div>
											<div className='mt-2'>
												<span className="details-desc text-success " style={{ fontSize: '14px' }}>En cours de construction...</span>
											</div>
										</>
									) : appleBuild === "50" ? (
										<>
											<div className=''>
												<button className='btn-generate50 text-center mt-3 '>
													<span>Chargement... {appleBuild}%</span>
												</button>
											</div>
											<div className='mt-2'>
												<span className="details-desc text-success " style={{ fontSize: '14px' }}>En cours de construction...</span>
											</div>
										</>
									) : appleBuild === "75" ? (
										<>
											<div className=''>
												<button className='btn-generate75 text-center mt-3 '>
													<span>Chargement... {appleBuild}%</span>
												</button>
											</div>
											<div className='mt-2'>
												<span className="details-desc text-success " style={{ fontSize: '14px' }}>En cours de construction...</span>
											</div>
										</>
									) : (
										<div className='pb-1'>
											<button className='btn-start text-center mt-3' >
												Terminé {appleBuild}%
											</button>
										</div>
									)
								) : (
									<div className='pb-1'>
										<Spinner animation="border" variant="success" role="status" size="lg" className="mt-3">
											<span className="visually-hidden text-light">Loading...</span>
										</Spinner>
									</div>
								)
							}
						</div>

						{/****** Android   ******/}
						<div className="col-sm-3 box-features ms-3 me-3 pt-3 ps-3 pe-3 pb-0 " >
							<div className='pb-1'  >
								<img className='apps-image' alt="Chargement"  style= {{textAlign: 'center',  width: '50%'}} src={android} />
							</div>
							<div className='pb-1'  >
								<span className='details-desc text-dark fw-bold mt-0 pt-0 '> Google Play </span> <br />
							</div>
							<div className='pb-1'  >
								<span className='details-desc fs-6 fw-bold mt-0 pt-0 '> { countAndroid } <i className="fa-solid fa-download ms-1"></i>  </span> <br />
							</div>
							{
								androidState === "false" ? (
									<>
										<div className=''>
											<button onClick={handleBuildAndroid} className='btn-start text-center mt-3'>
												Publier <i class="fa fa-share-alt me-2" aria-hidden="true"></i>
											</button>
										</div>
										<div className='mt-2'>
											<span className="text-success" style={{ fontSize: '14px', textDecoration: 'underline', fontWeight: '550' }}>Aucune version <i className="fa-solid fa-mobile-screen-button ms-1"></i> </span>
										</div>
									</>

								) : androidState === "true" ? (
									<>
										<div className=''>
											<button onClick={UpdateBuildAndroid} className='btn-start text-center mt-3'>
												Générer <i className="fa-solid fa-mobile-screen-button me-2"></i> 
											</button>
										</div>
										<div className='mt-2'>
										    <span className="text-success" style={{ fontSize: '14px', textDecoration: 'underline', fontWeight: '550' }}> 
											        Version {appleVersion} 
													<i className="fa-solid fa-mobile-screen-button ms-1"></i> 
											</span>
										</div>
									</>
								) : androidState === "pending" ? (
									androidBuild === "0" ? (
									<>
										<div className=''>
											<button className='btn-generate0 text-center mt-3 '>
												<span>Chargement... {androidBuild}%</span>
											</button>
										</div>
										<div className='mt-2 pb-1'>
											<span className="details-desc text-success " style={{ fontSize: '14px' }}>En cours de construction...</span>
										</div>
									</>
									) : androidBuild === "25" ? (
									<>
										<div className=''>
											<button className='btn-generate25 text-center mt-3 '>
												<span>Chargement... {androidBuild}%</span>
											</button>
										</div>
										<div className='mt-2 pb-1'>
											<span className="details-desc text-success " style={{ fontSize: '14px' }}>En cours de construction...</span>
										</div>
									</>
									) : androidBuild === "50" ? (
									<>
										<div className=''>
											<button className='btn-generate50 text-center mt-3 '>
												<span>Chargement... {androidBuild}%</span>
											</button>
										</div>
										<div className='mt-2 pb-2'>
											<span className="details-desc text-success " style={{ fontSize: '14px' }}>En cours de construction...</span>
										</div>
									</>
									) : androidBuild === "75" ? (
									<>
										<div className=''>
											<button className='btn-generate75 text-center mt-3 '>
												<span>Chargement... {androidBuild}%</span>
											</button>
										</div>
										<div className='mt-2 pb-2'>
											<span className="details-desc text-success" style={{ fontSize: '14px' }}>En cours de construction...</span>
										</div>
									</>
									) : (
										<div className='pb-3'>
											<button className='btn-start text-center mt-3' >
												Terminé {androidBuild}%
											</button>
										</div>
									)
								) : (
									<div className='pb-3'>
										<Spinner animation="border" variant="success" role="status" size="lg" className="mt-3">
											<span className="visually-hidden text-light">Loading...</span>
										</Spinner>
									</div>
								)
							}
						</div>
				  </div> 
				</div>
			</div> 
		</div>
  );
}

export default Project;
