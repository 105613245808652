import React, { useState } from 'react';
import './../css/Contact.css';
import {  Link } from 'react-router-dom';
import { useAuth } from '../firebase/AuthContext';
import { useAuthState }  from 'react-firebase-hooks/auth';
import { getDownloadURL, ref, uploadBytes, getStorage } from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { auth, storage, db } from '../firebase';
import { useForm } from 'react-hook-form';


function Create() {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState('');
    const [build, setBuild] = useState(null);
    const [icLaunch, setIcLaunch] = useState(null); // 
    const [icNotification, setIcNotification] = useState(null); // 
	
	const [resultMessage, setResultMessage] = useState();
	const [messageType, setMessageType] = useState(''); // 'success' or 'error'
    const [loading, setLoading] = useState(false);
	
	const [use] = useAuthState(auth);


	// Fonction pour vérifier les dimensions de l'image
	const checkImageDimensions = (file, expectedWidth, expectedHeight) => {
		return new Promise((resolve, reject) => {
		  const img = new Image();
		  img.onload = () => {
			if (img.width === expectedWidth && img.height === expectedHeight) {
			  resolve(true);
			} else {
			  reject(`L'image doit avoir les dimensions de ${expectedWidth}x${expectedHeight} pixels.`);
			}
		  };
		  img.onerror = () => reject('Erreur lors du chargement de l\'image.');
		  img.src = URL.createObjectURL(file);
		});
	  };


    const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setResultMessage(''); // Clear previous result message
		
		if (!use) {
			setLoading(false);
			setResultMessage('You must be logged in to upload files.');
			return;
		}

		try {
			
			// Vérification si tous les fichiers sont bien sélectionnés
			if (!build || !icLaunch || !icNotification) {
			  setResultMessage('All fields must have valid files.');
			  setLoading(false);
			  return;
			}

			await checkImageDimensions(icLaunch, 512, 512);
			await checkImageDimensions(icNotification, 48, 48);

			 // Vérification que le fichier build est un fichier .zip et respecte la taille de 50 Mo
			 const isZipFile = build.name.endsWith('.zip');
			 if (!isZipFile) {
				 setLoading(false);
				 setResultMessage('Le fichier de build doit être au format .zip.');
				 setMessageType('danger');
				 return;
			 }
	 
			if (build.size > 50 * 1024 * 1024) { // 50 Mo en octets
				 setLoading(false);
				 setResultMessage('Le fichier build ne doit pas dépasser 50 Mo.');
				 setMessageType('danger');
				 return;
			}

			// Vérification que le fichier icLaunch est un fichier .png ou .jpg
			const isPngOrJpgFile = icLaunch.name.endsWith('.png') || icLaunch.name.endsWith('.jpg');
			if (!isPngOrJpgFile) {
			setLoading(false);
			setResultMessage('Le fichier de icLaunch doit être au format .png ou .jpg');
			setMessageType('danger');
			return;
			}

			// Vérification que le fichier icLaunch est un fichier .png ou .jpg
			const isSvgFile = icNotification.name.endsWith('.svg');
			if (!isSvgFile) {
			setLoading(false);
			setResultMessage('Le fichier de icNotification doit être au format .svg ');
			setMessageType('danger');
			return;
			}
	
			// Upload du fichier "build" dans Firebase Storage
			const buildStorageRef = ref(storage, `files/${build.name}`);
			await uploadBytes(buildStorageRef, build);
			const buildFileURL = await getDownloadURL(buildStorageRef);

			// Upload de l'icône de lancement dans Firebase Storage
			const launchStorageRef = ref(storage, `files/${icLaunch.name}`);
			await uploadBytes(launchStorageRef, icLaunch);
			const icLaunchFileURL = await getDownloadURL(launchStorageRef);

			// Upload de l'icône de notification dans Firebase Storage
			const notificationStorageRef = ref(storage, `files/${icNotification.name}`);
			await uploadBytes(notificationStorageRef, icNotification);
			const icNotificationFileURL = await getDownloadURL(notificationStorageRef);
			
			// Référence à la collection 'entreprises'
			const entrepriseRef = collection(db, 'entreprises');

			// Ajouter un document à Firestore
			await addDoc(entrepriseRef, {
				  name: name,
				  description: description,
				  color: color,
				  icLaunchFileURL: icLaunchFileURL,
				  icNotificationFileURL: icNotificationFileURL,
				  build: buildFileURL,
				  status: false,
				  admin: use.uid,
				  createdAt: serverTimestamp() // Utilisation de serverTimestamp pour la date
			});
			
			setName('');
			setDescription('');
			setColor('');
			setIcLaunch('');
			setIcNotification('');
			setBuild('');

			setLoading(false);
			setResultMessage('Projet Crée avec succès!');
			setMessageType('success');
		} catch (error) {
			console.error("Error submitting form: ", error);
			setLoading(false);
			setResultMessage(`Erreur lors de la soumission : ${error.message}`);
			setResultMessage(`Erreur: ${error}`);
			setMessageType('danger');
		}
	};


    return (
      <div className="App" style={{  minHeight: '100vh', marginBottom: '3%' }}>
        <nav className="navbar fixed-top">
            <div className='container'>
                <div className="navbar-left">
                    <Link onClick={() => window.history.back()} className='fs-5' >
                       <i className="fas fa-arrow-left ms-1 fw-bold text-success"></i>
                    </Link>
                </div>
				<div className="navbar-right">
					<span className='fs-6 text-dark fw-bold'> Nouveau projet </span>
                </div>
            </div>
        </nav>
		
		<br /> 
		<div className="container mt-3 pt-3" style={{  background: '#f2f2f2', borderRadius: '9px' }}>
		    <form onSubmit={handleSubmit}>
			    <div className='col-md-12 float-left ps-2 pe-2 pb-3'>

				    <h5 className='text-success fs-4 fw-bolder mt-3'>
					    <i className="fa-solid fa-mobile-screen-button me-1"></i> Nouvelle App
					</h5>
					<br />
					{/* Project Section */}
					<h5 className='text-success fw-bolder mt-4 mb-4'>1. Projet</h5>
					<div className='input-box'>
						<h5 className='desc-input text-dark'> Nom (50 characters).</h5>
						<div className='mt-3'>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								className='form-control mt-2'
								placeholder=''
								maxLength="35"
								style={{ width: '100%'}}
							/>
						</div>
					</div>
					<br />
					<h5 className='desc-input text-dark'> Description (250 Characters).</h5>
					<div className='mt-3'>
						<textarea
							rows='3'
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							className='form-control mt-2 '
							placeholder=''
							maxLength="200"
						/>
					</div>
					<br />

					{/* Customization Section */}
					<h5 className='text-success fw-bolder mt-4'>2. Customisation</h5>

					<div className='input-box mt-4'>
						<h5 className='desc-input text-dark'> Charte graphique ou thème <span className='fs-6'>(Ex: #854858)</span></h5>
						<div className='mt-3'>
							<input
								type="text"
								value={color}
								onChange={(e) => setColor(e.target.value)}
								className='form-control mt-2 '
								placeholder=''
							/>
						</div>
					</div>
					<br />
					<div className='input-box mt-2'>
					    <h5 className='desc-input text-dark'> Icône de lancement <span className='fs-6'> (PNG or JPG & 512px * 512px)</span> </h5>
						<div className='mt-3'>
							<input
								type="file"
								className="form-control mt-2 "
								onChange={(e) => setIcLaunch(e.target.files[0])}
								required
							/>
						</div>
					</div>
					<br />
					<div className='input-box mt-2'>
					    <h5 className='desc-input text-dark'> Icône de notification <span className='fs-6'> (SVG & 48px * 48px)</span> </h5>
						<div className='mt-3'>
							<input
								type="file"
								className="form-control mt-2 "
								onChange={(e) => setIcNotification(e.target.files[0])}
								required
							/>
						</div>
					</div>
					<br />
					
					{/* Build Section */}
					<h5 className='text-success fw-bolder mt-4'>3. Build</h5>
					
					<div className='input-box mt-2'>
					    <h5 className='desc-input text-dark'>
							Soumettez le projet buildé: <b>Build.zip (50 Mo maximum)</b>
						</h5>
						<div className='mt-3'>
							<input
								type="file"
								className="form-control mt-2 "
								onChange={(e) => setBuild(e.target.files[0])}
								required
							/>
						</div>
					</div>
					<br />

					<div className='col-md-3 mt-3 mb-3'>
						<button
							className='btn-form mt-2 mb-4 fw-bold' 
							type="submit"
							disabled={loading}
						>
							{loading ? 'Soumission...' : 'Créer'} <i className="fa-solid fa-mobile-screen-button me-1"></i>
						</button>
					</div>

					{/* Result Message */}
					{resultMessage && (
						<div className={`input-box mt-3 text-${messageType === 'success' ? 'success' : 'danger'}`}>
							<h5 className='fs-6 fw-bold text-danger'> {resultMessage}  </h5>
						</div>
					)}
				</div>
		    </form>
		</div>
      </div>
    );
  }
  
  export default Create;
  