import React from "react";
import {  Link } from 'react-router-dom';
import developers from './img/developers.jpg';
import tableau from './img/tab.PNG';
//import capture1 from './img/2.PNG';
import teaser from './video/teaser.mp4';
import './css/Welcome.css';
import Navbar from './navbars/Navbar';
import Footer from './Footer';
import fullhouse from './img/fullhouse.jpg';
import bandja from './img/bandja.png';



function Welcome() {

    return (
        <div className="App ">
        
        <Navbar />
        <header className="App-header ">
            <div className='container bg-title ms-2 me-2 row justify-content-center'>
                <div className="col-sm-6 ps-2 pe-2">
                    <br /> 
                    {/* <h5 className='Brand-FleetBo mt-5 ms-2'> FleetBo </h5> */}
                    <h5 className='Brand-FleetBo mt-2 ms-2'> Fleetbo<sup className='text-light fw-bolder'>.io</sup> </h5>
                    <div className='col-md-12 ps-2 pe-2 pt-2 pb-2 '>
                        <h1 className='float-left Brand-little' >Créez des applications <br /> mobiles natives avec <br />JavaScript</h1>   
                        <p className=''>
							<span className='title1'>
							   <span>More performances, fewer resources. </span>
							</span>
							<br />
							<Link to='/join' className='console mt-3'>
							    Tester Fleetbo <i className="fas fa-tachometer-alt me-2"></i> 
							</Link >
                        </p>
                    </div>
                </div>
                <div className="col-sm-5 bg-media ">
                    <video
                         poster="https://mir-s3-cdn-cf.behance.net/project_modules/disp/583b6136197347.571361641da25.gif"
                         //onLoad={handleImageLoaded} // Appelé une fois l'image complètement chargée
                         //style={{ width : "100%", height: "100%" }} // Masquer l'image jusqu'à son chargement
                         src={teaser} 
                         muted autoplay='true' playsinline='true' 
                         loop='true' id="vid">  
                    </video>
                </div>
            </div>
            {/*
            <div className='ps-3 pe-3'>
            <p className='text-desc'>Transformez vos sites web en Appli non téléchargeable.</p>
            </div>
            */}
        </header>
		

        <div className='formules-box'>
            <div className="container mt-3 pb-4 bg-white ">
                <div className="row why-fleetbo justify-content-center">
                    <h2 className='mt-4 mb-4 sub-title'>Aperçu de <b className='text-success'>Fleetbo </b> </h2>
                    <div className="col-sm-5 box-features ps-4 pe-4 ms-2 me-2 mt-2">
                        <h5 className='detail-fleetbo text-dark fw-bolder mt-5'>
							Accélérez le developpement sans sacrifier les performances.
                        </h5>
                        <h5 className='details-desc text-success fw-bolder mt-3'>
                            Juste un éditeur de code et un navigateur.
                        </h5>
                        <div className='box-details-desc pb-3'>
                            <span className='details-desc text-success'>Fleetbo s'occupe du reste ! </span>
                        </div>
                        <div className='col-md-12 div-details-welcome mb-5 pt-1 pb-3'>
                            <h5 className='detail-fleetbo-desc'>
                                <b className='fw-bold'> Fleetbo</b> est le Framework tout-en-un Backend / Frontend qui permet de créer
                                rapidement et à coûts réduits des applications mobiles Android Kotlin et IOS Swift via JavaScript.
                            </h5>
                            <h5 className='detail-fleetbo-desc'>
							    En codant exclusivement en React.js et en publiant votre application sur AppStore et PlayStore, Fleetbo élimine les contraintes matérielles.
                            </h5>
                        </div>
                    </div>
                    <div className="col-sm-5 box-features-img  ms-2 me-2 ">
                        <img className='apps-image' alt="Chargement" src="https://storage.googleapis.com/imagina-static/images/site/features/event/inform/app/app/fr/web/visuel_event_1.webp" />
                    </div>
                </div>
            </div>
        </div>

        <div className='formules-box mb-0'>
            <div className="container box-devFleet mb-0 pb-4 bg-white">
                <div className="row box-devFleet-mobile justify-content-center">
                    <h3 className='sub-title mt-4 mb-4'>Pourquoi <b className='text-success'> Fleetbo </b> </h3>
                    <div className="col-sm-5 box-features ps-4 pe-4 ms-2 me-2 mt-2">
                        <h5 className='detail-fleetbo text-dark fw-bolder mt-5'>
                            Une approche hybride pour une performance native.
                        </h5>
                        <h5 className='details-desc text-success fw-bolder mt-3'>
                            Fluidité et simplicité de développement incomparable.
                        </h5>
                        <div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>
                            {/**  
                            <h5 className='detail-fleetbo-desc'>
                                <b className='fw-bolder'>Pourquoi développer avec FleetBo ?</b> <br /> 
                                Notre équipe evalue toutes les applications Fleetbo: <br /> <br /> 
                                &bull; Maitrise du framework <br />
                                &bull; Vitesse de chargement <br />
                                &bull; Fluidité des interfaces <br /> 
                                &bull; Stabilité de l'application
                            </h5>
                            */}
                            <h5 className='detail-fleetbo-desc'>
								Fleetbo combine un WebView natif optimisé pour les rendus statiques avec des 
								fonctionnalités natives 100% Kotlin/Swift pour les interactions et animations complexes. <br />
								Avec un coût compétitif et une infrastructure optimisée, Fleetbo permet aux développeurs de créer des 
								apps performantes sans nécessiter de matériel haut de gamme.
                            </h5>
                        </div>
                    </div>
                    <div className="col-sm-5 box-features-img p-0 ms-2 me-2">
                        <img src={developers} className='developers-image' alt="logo" /> 
                    </div>
                </div>
            </div>
        </div>  

       {/* 
        <div className='formules-box ' id="package">
            <div class="container pb-4 bg-white">
                <div class="row justify-content-center">
                    <h3 className='formule mt-4 mb-4'>Développeurs certifiés <b className='text-success'>Fleetbo </b> </h3>

                    <div class="col-sm-3 box-features p-0 ms-2 me-2 mt-4">
                        <div className='' style={{ width: '100%' }}>
                            <img style={{ width: '100%' }} src='https://www.studioah.fr/wp-content/uploads/2021/07/photo_professionel_studio_nantes_%C2%A9studioah_portrait_corporate-11-768x768.jpg' /> 
                        </div>
                        <div className='col-md-12 p-2 div-details-welcome'>
                            <h5 className='text-success'> Loic Standerson <sup className='text-light p-1' style={{ borderRadius: '12px', backgroundColor: '#d19507', fontWeight: '550', fontSize: '11px' }}> +10 d'exp </sup></h5>
                            <h5 className='fs-6' style={{ color: '#545454' }}><i class="fa-solid fa-location me-1"></i> Paris, France</h5>     
                            <div className="">
                                <div class="row" style={{ fontSize: '12px', fontWeight: '550' }}>
                                    <div class="col-md-auto p-2">
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>React JS</span>
                                    </div>
                                    <div class="col-md-auto p-2" >
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>Node JS</span>
                                    </div>
                                </div>
                                <br />
                                <a href='/Basic' className='btn-start fs-6 text-center mt-1'>Contacter <i class="fa-solid fa-link me-2"></i> </a>
                            </div>
                            <br />
                        </div>
                        <br /> <br />
                    </div>

                    <div class="col-sm-3 box-features p-0 ms-2 me-2 mt-4">
                        <div className='' style={{ width: '100%' }}>
                            <img style={{ width: '100%' }} src='https://www.studioah.fr/wp-content/uploads/2021/07/photo_professionel_studio_nantes_%C2%A9studioah_portrait_corporate-11-768x768.jpg' /> 
                        </div>
                        <div className='col-md-12 p-2 div-details-welcome'>
                            <h5 className='text-success'> Loic Standerson <sup className='text-light p-1' style={{ borderRadius: '12px', backgroundColor: '#d19507', fontWeight: '550', fontSize: '12px' }}> +5 </sup></h5>
                            <h5 className='fs-6' style={{ color: '#545454' }}><i class="fa-solid fa-location me-1"></i> Paris, France</h5>     
                            <div className="">
                                <div class="row" style={{ fontSize: '12px', fontWeight: '550' }}>
                                    <div class="col-md-auto p-2">
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>React JS</span>
                                    </div>
                                    <div class="col-md-auto p-2" >
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>Node JS</span>
                                    </div>
                                </div>
                                <br />
                                <a href='/Basic' className='btn-start fs-6 text-center mt-1'>Contacter <i class="fa-solid fa-link me-2"></i> </a>
                            </div>
                            <br />
                        </div>
                        <br /> <br />
                    </div>

                    <div class="col-sm-3 box-features p-0 ms-2 me-2 mt-4">
                        <div className='' style={{ width: '100%' }}>
                            <img style={{ width: '100%' }} src='https://www.studioah.fr/wp-content/uploads/2021/07/photo_professionel_studio_nantes_%C2%A9studioah_portrait_corporate-11-768x768.jpg' /> 
                        </div>
                        <div className='col-md-12 p-2 div-details-welcome'>
                            <h5 className='text-success'> Loic Standerson <sup className='text-light p-1' style={{ borderRadius: '12px', backgroundColor: '#d19507', fontWeight: '550', fontSize: '12px' }}> +20 </sup></h5>
                            <h5 className='fs-6 text-dark'><i class="fa-solid fa-location me-1"></i> Paris, France</h5>     
                            <div className="">
                                <div class="row" style={{ fontSize: '12px', fontWeight: '550' }}>
                                    <div class="col-md-auto p-2">
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>React JS</span>
                                    </div>
                                    <div class="col-md-auto p-2" >
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>Node JS</span>
                                    </div>
                                </div>
                                <br />
                                <a href='/Basic' className='btn-start fs-6 text-center mt-1'>Contacter <i class="fa-solid fa-link me-2"></i> </a>
                            </div>
                            <br />
                        </div>
                        <br /> <br />
                    </div>
                
                </div>
                <br /> <br />
                <a href='/Basic' className='fw-bold text-dark fs-5 text-center'> <h4 className='fs-6 fw-bolder '>Plus de développeurs  <i class="fa-solid fa-plus me-2"></i> </h4></a>
            </div>    
        </div>  
        */}
		
		<div className='formules-box ' id="package">
            <div className="container ">
                <div className="row div-formules pb-4 bg-white justify-content-center">
                    <h3 className='sub-title mt-4 mb-4'>Fonctionnalités de <b className='text-success'>Fleetbo </b> </h3>
					
					<div className="col-sm-3 box-features pt-4 m-2" style={{ height: '200px' }}>
					    <i className="fa-solid fa-user-shield fs-2 text-success "></i>
                        <h5 className='text-success fw-bolder text-center mt-3 fs-6'> <b style={{ fontSize: '20px' }}>Gestion de comptes</b> </h5>
                        <div className='box-details-desc text-center'>
                            <span className='details-desc text-center fs-6'> Authentification </span>
							<h5 className='details mt-2 text-center fs-6'>Faites abstraction des fonctions d'authentification et de déconnexion. </h5>
                        </div>
                    </div>
                    <div className="col-sm-3 box-features pt-4 m-2" style={{ height: '200px' }}>
					    <i className="fa-solid fa-database fs-2 text-success"></i>
                        <h5 className='text-success fw-bolder text-center mt-3 fs-6'> <b style={{ fontSize: '20px' }}>Base de données</b> </h5>
                        <div className='box-details-desc text-center'>
                            <span className='details-desc text-center fs-6'> Une base de données NoSQL </span>
							<h5 className='details mt-2 text-center fs-6'>Gérez vos données via des fonctions natives prédéfinies. </h5>
                        </div>
                    </div>
					<div className="col-sm-3 box-features pt-4 m-2" style={{ height: '200px' }}>
					    <i className="fa-solid fa-bell fs-2 text-success"></i>
                        <h5 className='text-success fw-bolder mt-3 text-center fs-6'> <b style={{ fontSize: '20px' }}>Notifications push</b> </h5>
                        <div className='box-details-desc text-center'>
                            <span className='details-desc text-center fs-6'> Système de notifications push native </span>
							<h5 className='details mt-2 text-center fs-6'>Des notifications push grace à une simple fonction JavaScript. </h5>
                        </div>
                    </div>
					<div className="col-sm-3 box-features pt-4 m-2" style={{ height: '200px' }}>
					    <i className="fa-solid fa-mobile fs-2 text-success"></i>
                        <h5 className='text-success fw-bolder mt-3 text-center fs-6'> <b style={{ fontSize: '20px' }}>Android & iOS </b> </h5>
                        <div className='box-details-desc text-center'>
                            <span className='details-desc text-center fs-6'> PlayStore & AppStore </span>
							<h5 className='details mt-2 text-center fs-6'>Publication de votre application sur les plateformes de téléchargement. </h5>
                        </div>
                    </div>
					<div className="col-sm-3 box-features pt-4 m-2" style={{ height: '200px' }}>
					    <i className="fa-solid fa-camera fs-2 text-success"></i>
                        <h5 className='text-success fw-bolder mt-3 text-center fs-6'> <b style={{ fontSize: '20px' }}>Caméra </b> </h5>
                        <div className='box-details-desc text-center'>
                            <span className='details-desc text-center fs-6'> Accès natif à la caméra </span>
							<h5 className='details mt-2 text-center fs-6'>Des fonctions pour acceder nativement à l'outil caméra. </h5>
                        </div>
                    </div>
					<div className="col-sm-3 box-features pt-4 m-2" style={{ height: '200px' }}>
					    <i className="fa-solid fa-file fs-2 text-success"></i>
                        <h5 className='text-success fw-bolder mt-3 text-center fs-6'> <b style={{ fontSize: '20px' }}>Gestion de fichiers </b> </h5>
                        <div className='box-details-desc text-center'>
                            <span className='details-desc text-center fs-6'> Accès natif aux fichiers </span>
							<h5 className='details mt-2 text-center fs-6'>Des fonctions pour acceder nativement aux images et PDF. </h5>
                        </div>
                    </div>
					<div className="col-sm-3 box-features pt-4 m-2" style={{ height: '200px' }}>
					    <i className="fa-brands fa-bluetooth fs-2 text-success"></i>
                        <h5 className='text-success fw-bolder mt-3 text-center fs-6'> <b style={{ fontSize: '20px' }}>Bluetooth </b> </h5>
                        <div className='box-details-desc text-center'>
                            <span className='details-desc text-center fs-6'> Accès natif au bluetooth </span>
							<h5 className='details mt-2 text-center fs-6'>Des fonctions pour acceder nativement à l'outil bluetooth. </h5>
                        </div>
                    </div>
					<div className="col-sm-3 box-features pt-4 m-2" style={{ height: '200px' }}>
					    <i className="fa-solid fa-credit-card fs-2 text-success"></i>
                        <h5 className='text-success fw-bolder mt-3 text-center fs-6'> <b style={{ fontSize: '20px' }}>Paiement en ligne </b> </h5>
                        <div className='box-details-desc text-center'>
                            <span className='details-desc text-center fs-6'>Un outil de paiement en ligne intégré </span>
							<h5 className='details mt-2 text-center fs-6'>Accedez nativement à notre outil de paiement prêt à l'emploi. </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		
		<div className='formules-box mb-0'>
            <div className="container box-devFleet mb-0 pb-4 bg-white">
                <div className="row box-devFleet-mobile justify-content-center">
                    <h3 className='sub-title mt-4 mb-4'> Concurrence vs <b className='text-success'> Fleetbo </b> </h3>
                    <div className="col-sm-6 box-features-mobile p-4 ms-2 me-2" >
                        <img src={tableau} className='developers-image' alt="logo" /> 
                    </div>
                </div>
            </div>
        </div>  
		
		{/*
		<div className='formules-box ' id="package">
            <div className="container ">
                <div className="row div-formules pb-4 bg-white justify-content-center">
                    <h3 className='formule mt-4 mb-4'>NOS <b className='text-success'>FORMULES </b> </h3>
                    <div className="col-sm-4 box-features pt-4 ps-4 mt-1">
                        <h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Developers</b> </h5>
                        <div className='box-details-desc pb-3'>
                            <span className='details-desc '> Conçue pour tests des developpeurs </span>
                        </div>
                        <div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>   
                            
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> 01 base de données NoSQL fournie. </h5> 
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> API Rest intégrée pour vos requêtes. </h5> 		
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>				
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> Vos fichiers apk et IPA en 24H.</h5> 
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> Jusqu'à 50 campagnes pour tests. </h5>
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> 50 Push notifications autorisés. </h5> 
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> 01 Go de stockage disponibles. </h5>
							<h5 className='details '><i className="fa-solid fa-check me-2"></i> 100 requêtes à la base pour tests. </h5> 							 
                        </div>
                        <div className='box-details-prices'>
                            <h3 className='mt-2 fs-4 fw-bold text-success'> <i className="fa-solid fa-gift me-1"></i> Gratuit </h3>
                            <h4 className='mt-2 fs-6 text-success' style={{fontWeight: '600'}}> <b className='ms-0 text-dark' style={{fontWeight: '550', fontSize: '14px'}}> 14 jours offerts</b> </h4>
                            <h4 className='fs-6 text-success'> <i className="fa-solid fa-toggle-on me-1"></i> <b  style={{ fontWeight: '505'}}>Launch</b> après 14 jours</h4> 
                        </div>
                        <br /> 
                    </div>
                    <div className="col-sm-4 box-features bf-launch pt-4 ps-4 ms-4 me-4" style={{ border: '3px solid #05b365;' }}>
                        <h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Launch</b> </h5>
                        <div className='box-details-desc pb-3'>
                            <span className='details-desc '> Conçue pour les startups en démarrage </span>
                        </div>
                        <div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> 01 base de données NoSQL fournie. </h5>   
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> API Rest intégrée pour vos requêtes. </h5> 
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>  						
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
                            <h5 className='details '><i className="fa-solid fa-check me-2"></i> Vos fichiers apk et IPA en 24H.</h5> 
                            <h5 className='details '><i className="fa-solid fa-circle-check text-success fw-bold me-2"></i> Jusqu'à 300 campagnes par an. </h5> 
                            <h5 className='details '><i className="fa-solid fa-circle-check text-success fw-bold me-2"></i> 3 M Push notifications par mois. </h5>
                            <h5 className='details '><i className="fa-solid fa-circle-check text-success fw-bold me-2"></i> 50 Go de stockage disponibles. </h5>
							<h5 className='details '><i className="fa-solid fa-circle-check text-success fw-bold me-2"></i> 1 M de requêtes à la base par mois. </h5>							
                        </div>
                        <div className='box-details-prices'>
                            <h3 className='mt-2 fs-4 fw-bold text-success'> 9.99 &euro; / mois </h3>
                            <h4 className='mt-2 fs-6 text-dark' style={{fontWeight: '600'}}>Ou 110 &euro; par an </h4>
                            <h4 className='fs-6 text-success'> <i className="fa-solid fa-gift me-1"></i> <b>0.99 &euro;</b> le premier mois </h4> 
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </div>
        */}
        <div className='formules-box ps-3 pe-3 pt-2 pb-1'  id='package'> </div>

        <div className='pt-3'>
            <div className="container">
                <div className="row justify-content-center">
                    <h3 className='sub-title mt-3'>Ils nous font <b className='text-success'>CONFIANCE</b> </h3>
                    <div className="col-sm-1  pt-4 pb-4 ms-3 me-3 mt-4 mb-4">
						<img className='logos' src="https://i0.wp.com/www.univ-dschang.org/wp-content/uploads/2023/08/logo-UDs-Article.jpg?fit=665%2C626&ssl=1" alt="Logo" /> <br />
						<span className='partners'>UDs</span>
                    </div>
                    <div className="col-sm-1  pt-4 pb-4 ms-3 me-3 mt-4 mb-3">
						<img className='logos' src="https://www.talanoo.com/img/tal1.png" alt="Logo" /> <br />
						<span className='partners'>Talanoo</span>
                    </div>
					<div className="col-sm-1  pt-4 pb-4 ms-3 me-3 mt-4 mb-3">
						<img className='logos' src={fullhouse}  alt="Logo" /> <br />
						<span className='partners'>FullHouse</span>
                    </div>
					<div className="col-sm-1  pt-4 pb-4 ms-3 me-3 mt-4 mb-3">
						<img className='logos' src={bandja}  alt="Logo" /> <br />
						<span className='partners'>Bandja</span>
                    </div>
                    <div className="col-sm-1  pt-4 pb-4 ms-3 me-3 mt-4 mb-3">
						<img className='logos' src="https://upload.wikimedia.org/wikipedia/fr/a/ad/FT_logo-RS_%281%29.png" alt="Logo" /> <br />
						<span className='partners'>La French Tech</span>
                    </div>
                </div>
            </div>
        </div>

        <Footer />

        </div>
    );
}

export default Welcome;
