import React from 'react';
import { Link } from 'react-router-dom';
import './css/Navbar.css';
import logo1 from './img/logo1.png';
import assistant from './img/assistant.png';



const Navbar = () => {
  return (
  <nav className="navbar fixed-top bg-white">
    <div className='container '>
      <div className="">
        <Link to="/">
          <img src={logo1} className="" alt="logo" /> 
        </Link>
      </div>
      <div className="navbar-right">
                <Link to="/login" className='connexion ps-1 pe-1 fw-bold' style={{ width: '199px', fontSize: '14px', borderRadius: '25px' }} >  
                    Console Fleetbo <i className="fas fa-tachometer-alt"></i> 
                </Link>
                <a href="mailto:contact@fleetbo.io" className="">
                   <img src={assistant} className="assistant ms-1" alt="logo" /> 
                </a>
                {/**
                <a href="mailto:contact@fleetbo.io" className="cart-icon">
                    <i className="fas fa-envelope fs-5 me-3"></i>
                </a>
				
                <Link to="/en" className="go-to-console fw-bolder" aria-label="User Account">
                    En 
                </Link>
                **/}
            </div>
    </div>
  </nav>
  );
};

export default Navbar;