import React, { useEffect, useState } from 'react';
import './css/Welcome.css';
import './css/Login.css';
import Footer from './Footer';
import { auth } from './firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useAuthState }  from 'react-firebase-hooks/auth';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import assistant from './navbars/img/assistant.png';


function Login() {

    const navigate = useNavigate();

    const [user] = useAuthState(auth);
	
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');


    const { pathname } = useLocation(); // Récupère le chemin de la route actuelle
    useEffect(() => {
      // Défile immédiatement au sommet sans animation
      window.scrollTo(0, 0);
    }, [pathname]); // Appelle window.scrollTo à chaque changement de route


    if(user){
		//alert(user.uid);
      navigate('/home');
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
			setError(error.message);
            //setError('Email ou mot de passe incorret.');
        }
    };

	return (
		<div className="" style={{ OverflowY: 'hidden' }}  id='id'>
		
		  <header className="App-header" style={{ overflowY: 'hidden' }} >
		  <nav className="navbar fixed-top">
				<div className='container'>
					<div className="navbar-left">
						<Link to="/">
							 <i className="fas fa-arrow-left ms-1 fw-bolder text-success"></i>
						</Link>
					</div>
					<div className="navbar-right">
						<span className='go-to-console me-4'> </span>
						<Link to="/join" className='connexion text-center ps-3 pe-3' style={{ width: '160px', fontSize: '14px', borderRadius: '25px' }} >  
							S'inscire 
						</Link>
						<a href="mailto:contact@fleetbo.io" className="cart-icon">
						    <img src={assistant} className="assistant" alt="logo" /> 
						</a>
					</div>
				</div>
			</nav>
		    <br /> <br /> 
			<h5 className='Brand-FleetBo mt-2'> Fleetbo<sup className='text-light fw-bolder fs-2'>.io</sup></h5>
			<div className='col-md-6 ps-2 pe-2'>
				<p className='box-subtitle'>
					<span className='title1'>
						<span className='brand'>Empowering web developers</span> 
					</span>
				</p> 
			</div>

			<div className='box-form text-center justify-content-center'>

				{!user ? (
				  <div className='p-0' style={{ height: '400px' }}>
					
					<form onSubmit={handleLogin}>
					  <div className=' p-0 m-0 justify-content-center'>
							<div className=' mb-2'>
							  <label className='ps-0 fs-6 label'>E-mail</label>
							  <input type="email" 
									className='form-control' 
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
							</div>
							<div className='mb-4'>
							  <label className='ps-0 fs-6 label'>Mot de passe</label>
							  <input type="password" 
									 value={password} className='form-control'
									 onChange={(e) => setPassword(e.target.value)}
									 required
								/>
							</div>
							<div className='mb-4 mt-2 justify-content-center d-flex'>
							    <button type='submit' className='connexion' >  
							       Se connecter <i className="fa-solid fa-code"></i>
								</button>
							</div>
						    <h5 className="beta text-white text-center" style={{ fontSize: '14px'}}>
							   <u>Plus de performances, moin de ressources</u>
							</h5>
							{/*
						    <div className='mb-3 justify-content-center d-flex'>
							   <Link to='/login' className='inscription' >  Se connecter </Link>
							</div>
							*/}
							<div className='mb-3 justify-content-center d-flex'>
							    {error && 
									<p className='text-white fw-bold text-center p-2 bg-danger' style={{ width: '150%', fontSize: '13px' }}>
										{error}
									</p> 
								}
							</div>

							<div className='mb-3 justify-content-center d-flex'>
								<p className='text-white fw-bold text-center p-2 ' style={{ width: '150%', fontSize: '12px' }}>
								   © 2025 Fleetbo
								</p> 
							</div>
					  </div>
					</form>
				  </div>
				  
				) : (
				  <p>Welcome, {user.email}</p>
				)}
			</div>

			 {/* show login error msg */}
			 {/*}
			 {loginError!==''&&(
				<div className='error-msg'>{loginError}</div>
			  )}
			*/}
		  </header>

		</div>
	);
}

export default Login;
